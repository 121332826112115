import React from "react"
// import { Link } from "gatsby" // we use wrapper one from plugin = gatsby-plugin-intl
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { graphql } from "gatsby"

// s4n custom code
import loadable from '@loadable/component'
import FragmentPageDetails from './../model/fragments/nodePage/page-details'
import { navigate, useIntl } from "gatsby-plugin-intl"
import { useParseInlineImages } from '../hooks/drupal/parseInlineImages'
// import DrupalModuleParagraphsContainer from './../components/drupal/module/paragraphs/container'
// import Widgets from "./../components/widgets"
// import Team from './../components/s4n/Team/index-static';
// import ImagesIllustrationsLeafBg from './../assets/images/illustrations/leaf-bg.png';
// import Portfolio from './../components/s4n/Portfolio'
// import Partners from './../components/s4n/Partners'
import Testimonials from './../components/s4n/Testimonials'

// Subpages
// import kebabCase from "lodash.kebabcase"
// import Link from "./../components/s4n/Link"
// import Page from "./../model/node/page"
// import ImageS4n from './../components/s4n/image'
import useReplacementNode from './../hooks/drupal/parseContentProcessor/useReplacement/Node'

// import Hero from './../components/s4n/ProjectSpecific/Hero'
// import ProductsList from './../components/s4n/ProjectSpecific/ProductsList'
// import Categories from './../components/s4n/ProjectSpecific/Categories'
// import Categories2 from './../components/s4n/ProjectSpecific/Categories2'
// import Cta from './../components/s4n/ProjectSpecific/Cta'
// import CollectionSlider from './../components/s4n/ProjectSpecific/CollectionSlider'
// import Deal from './../components/s4n/ProjectSpecific/Deal'
// import Instagram from './../components/s4n/ProjectSpecific/Instagram'
// import QuickView from './../components/s4n/ProjectSpecific/QuickView'
// import Brand from './../components/s4n/Shop/Components/Brand'
import ReadMore from './../components/s4n/ReadMore'


const IndexPage = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { data } = props;

    // console.log(data)

    // const node = data.allNodePage.edges[0].node;
    // const { node } = data.allNodePage.edges.filter(({ node }) => intl.locale === node.langcode)[0];
    let nodeInLanguage = data.allNodePage.edges.filter(({ node }) => intl.locale === node.langcode); // just for netlify!!!
    nodeInLanguage = !!nodeInLanguage.length ? nodeInLanguage[0].node : null;
    
    let nodeBodyField = null;
    // let nodeParagraphField = null;
    const oReplacement = useReplacementNode(nodeInLanguage);

    // if (nodeInLanguage) {
      // prepare/extract data
      // let nodeBodyField = node.body ? node.body.value : null;
      nodeBodyField = useParseInlineImages(nodeInLanguage, true, oReplacement); // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
      // nodeParagraphField = nodeInLanguage.relationships ? nodeInLanguage.relationships.field_paragraph : null
      // let nodeParagraphField = useParseBodyInlineImages(nodeInLanguage.relationships.field_paragraph)
	// }
	
	const ProductRelated = loadable(() => import(`./../components/s4n/Shop/Product/related`));

    return (
      	<Layout node={nodeInLanguage}>

			<SEO 
				nodeId={nodeInLanguage.drupal_internal__nid} 
				// title={nodeInLanguage.title} 
				title={t({ id: `siteMetadata_title_home_page` })} 
				defaultTitle=""
				field_metatag={nodeInLanguage.field_metatag}
			/>
			
			{nodeBodyField}

			<div className={`container`}>

				<div className={`decoration-stripe`}></div>

				<div className={`row items three-boxes`}>					
					<div className={`col-12 third-party mt-5`}>
						<div className="sec-Section_Header sec-Section_Header-large">
							<h3 className="sec-Section_Subtitle">Ręcznie wyplatane wianki</h3>
							<h2 className="sec-Section_Title">Poznaj naszą ofertę</h2>
						</div>
					</div>

					<React.Fragment>
						<div className={`col-lg-6`}>
							<div onClick={() => navigate(`/wianki`)} className={`item cloud rounded bg-blue`}>
								{/* <div> */}
									<div className={`title`}>Wianki gotowe</div>
									<div className={`content`}>Pory roku,<br />okolicznościowe, święta</div>
									<ReadMore className={`black my-0`} to={Number(process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID)} title={ t({ id: "soft4net_more" }) } />
								{/* </div> */}
							</div>
						</div>
						<div className={`col-lg-6`}>
							<div onClick={() => navigate(`/wianki/na-zamowienie`)} className={`item cloud rounded bg-blue`}>
								{/* <div> */}
									<div className={`title`}>Na zamówienie</div>
									<div className={`content text-center`}>Ty masz pomysł,<br />my zakręcimy go wokół wianka</div>
									<ReadMore className={`black my-0`} to={29} title={ t({ id: "soft4net_more" }) } />
								{/* </div> */}
							</div>
						</div>
						<div className={`col-lg-6`}>
							<div onClick={() => navigate(`/wianki/dla-firm`)} className={`item cloud rounded bg-blue`}>
								{/* <div> */}
									<div className={`title`}>Dla firm</div>
									<div className={`content`}>Zamów wianki<br />dla kontrahentów</div>
									<ReadMore className={`black my-0`} to={30} title={ t({ id: "soft4net_more" }) } />
								{/* </div> */}
							</div>
						</div>
						<div className={`col-lg-6`}>
							<div onClick={() => navigate(`/wianki/wianki-z-naturalnych-kwiatow`)} className={`item cloud rounded bg-blue`}>
								{/* <div> */}
									<div className={`title`}>Z naturalnych kwiatów</div>
									<div className={`content`}>
										&nbsp;
										{/* Zamów wianki<br />dla kontrahentów */}
									</div>
									<ReadMore className={`black my-0`} to={30} title={ t({ id: "soft4net_more" }) } />
								{/* </div> */}
							</div>
						</div>
					</React.Fragment>
					
				</div>

				<div className={`decoration-stripe pt-0`}></div>


				<div className={`third-party mt-5`}>
					<div className="sec-Section_Header sec-Section_Header-large">
						<h2 className="sec-Section_Title">Nasze nowości</h2>
					</div>
				</div>

				<section className="section p-0">
                	<div className="container px-0">
						<ProductRelated 
							disableHeader={true} 
							products={nodeInLanguage.relationships && nodeInLanguage.relationships.field_related_products ? nodeInLanguage.relationships.field_related_products : []} 
							limit={11}
							customLastElement={(() => {
								const translation = {
									link_wreaths: {
										pl: `/wianki`,
										en: `/wreaths`,
										de: `/wreaths`,
									},
								}

								return (
									<div key={`product-index-card-customLastElement`} className={`col-xl-3 col-lg-4 col-sm-6`}>
										<div className={`square`}>
											<div onClick={() => navigate(translation.link_wreaths[intl.locale])} className={`item bg-grey`}>
												<div className={`title`}>{ t({ id: `soft4net_more_wreaths` }) }</div>
												<ReadMore to={translation.link_wreaths[intl.locale]} className="my-0" title={ t({ id: `soft4net_more_wreaths` }) } />
											</div>
										</div>
									</div>
								)
							})()}
						/>
					</div>
				</section>

				{/* <CollectionSlider header={t({ id: `soft4net_page_home_collection_slider_recommended` })} /> */}
				{/* <ProductsList /> */}
				{/* <section className="section s4n-collection-slider">
					<div className="container px-0">
						<div className="row">
							<div className="col-lg-12 text-center">
								<h2 className="section-title">{header}</h2>
							</div>
							<div className="col-12">
								<div className="row product-list">
									{[1, 2, 3, 4, 5, 6, 7, 8].map(index => {
										const node = {};
										return (
											<div key={`product-index-card-${index}`} className={`col-lg-4 col-sm-6 mb-4`}>
												<ProductCard product={node} />
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</div>
				</section> */}

			</div>

			{/* <Categories /> */}
			{/* <Categories2 /> */}
			{/* <Brand /> */}

			{/* <Cta /> */}
			{/* <QuickView /> */}
			{/* <Deal /> */}

			{/* <Instagram /> */}

			{/* <h1>Hi people!</h1> */}
			{/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
				<Image />
			</div> */}
			{/* <Link to="/page-2/">Go to page 2</Link> */}
			{/* { t({ id: "title" }) } */}
			{/* <p>{ nodeInLanguage.title }</p> */}
			{/* <div dangerouslySetInnerHTML={{ __html: nodeInLanguage.body ? nodeInLanguage.body.value : '' }}></div> */}

			{/* <Team /> */}
			{/* <Subpages 
				nid={14} 
				template={`service`}
			/> */}
			{/* <Partners /> */}
			{/* <Testimonials /> */}
			{/* @see: https://github.com/gatsbyjs/gatsby/issues/8787 */}
			{/* { '/pl/' === props.location.pathname && <Widgets />} */}
			{/* <DrupalModuleParagraphsContainer>
				{nodeParagraphField}
			</DrupalModuleParagraphsContainer> */}
			{/* {node.edges.map(({ node }) => {
				return (
					<>
						<h3><Link to={ node.path.alias }>{ node.title }</Link></h3>
						<small><em>{ node.created }</em></small>
						<div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value.split(' ').splice(0, 50).join(' ') + '...' : '' }}></div>
						<div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value : '' }}></div>
					</>
				)
			})} */}

      	</Layout>
    )
}

export default IndexPage

export const query = graphql`
  query {
    allNodePage (
        filter: {
          drupal_internal__nid: {
            eq: 1
          }
          # langcode: {
          #   eq: "pl"
          # }
        }
      ) {
        edges {
          node {
            ...FragmentPageDetails
          }
        }
    }
  }
`